'use client';

import { useEffect } from 'react';

import ErrorPage from '@shared/common/components/ErrorPage/ErrorPage';
import { ERROR_PAGE_CODE } from '@shared/constants/errorCodes';

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error('handle error');
    console.error(error);
  }, [error]);

  return <ErrorPage code={ERROR_PAGE_CODE.ERROR} />;
}
